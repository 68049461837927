import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import { Helmet } from 'react-helmet-async';
import { Fade } from "react-awesome-reveal";

export default function PrivacyPolicy() {


  return (
    <>
      <Helmet>
        <title>Zásady ochrany osobných údajov - LucasDesign</title>
        <meta name="title" content="Kontakt - LucasDesign" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
        <meta name="keywords" content="web developer,developer,website,web,custom" />
        <meta name="robots" content="index, follow" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
      </Helmet>
      <section className='section-hero'>

        <div className="container-inner">
          <div className="row">
            <div className="col-12">
              <Fade triggerOnce duration={1000} direction="up">
                <h1>Zásady ochrany osobných údajov</h1>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section className='legal'>
        <p>Tieto zásady ochrany osobných údajov vysvetľujú, ako zhromažďujeme, používame a chránime vaše osobné údaje, keď používate našu webovú stránku.</p>

        <h4>1. Zber informácií</h4>

        <p>Zhromažďujeme osobné údaje, ktoré nám poskytujete priamo, ako napríklad vaše meno, e-mailovú adresu a správy, ktoré nám posielate prostredníctvom kontaktného formulára.</p>

        <h4>2. Používanie informácií</h4>

        <p>Vaše osobné údaje používame na nasledujúce účely:</p>

        <ul>
          <li>Na odpovedanie na vaše otázky a správy.</li>
          <li>Na zlepšovanie našej webovej stránky a služieb.</li>
          <li>Na zasielanie noviniek a informácií, ak ste súhlasili s ich prijímaním.</li>
        </ul>


        <h4>3. Ochrana údajov</h4>

        <p>Zaväzujeme sa chrániť vaše osobné údaje a prijímame vhodné technické a organizačné opatrenia na zabezpečenie ich ochrany pred neoprávneným prístupom, zneužitím alebo zverejnením.</p>

        <h4>4. Zdieľanie údajov</h4>

        <p>Vaše osobné údaje nepredávame, nevymieňame ani neprenajímame tretím stranám bez vášho súhlasu, s výnimkou prípadov, keď to vyžaduje zákon.</p>

        <h4>5. Vaše práva</h4>

        <p>Máte právo na prístup k svojim osobným údajom, ich opravu, vymazanie alebo obmedzenie spracovania. Ak si želáte uplatniť tieto práva, kontaktujte nás prostredníctvom kontaktných údajov uvedených na našej webovej stránke.</p>

       <h4>6. Zmeny v zásadách ochrany osobných údajov</h4>

       <p>Tieto zásady ochrany osobných údajov môžeme čas od času aktualizovať. Ak vykonáme zmeny, zverejníme aktualizované zásady na tejto stránke a zmeníme dátum účinnosti na začiatku dokumentu.</p>

        <h4>7. Kontakt</h4>

        <p>Ak máte akékoľvek otázky alebo obavy týkajúce sa týchto zásad ochrany osobných údajov, kontaktujte nás na adrese <a href="mailto:info@lucasdesign.sk">info@lucasdesign.sk</a>.</p>

        <p>Dátum účinnosti: 02.06.2024</p>
      </section >
    </>

  )
}