import Logo from '../logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t, i18n } = useTranslation();

    // Get the current language
    const currentLang = i18n.language === 'sk' ? '' : `/${i18n.language}`;

    const currentYear = new Date().getFullYear();

    return (
        <footer>

            <div className="container">
                <div className="container-inner">
                    <div className="row gy-4">
                        <div className="col-sm-12 col-md-6">
                            <div className="footer-1">
                                <a href="/"><img src={Logo} alt="logo" /></a>
                                <p>{t('Spolupracujme a vytvárajme spolu výnimočné webové zážitky!')}</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <h4>{t('Sociálne siete')}</h4>
                            <ul className="vstack gap-2 align-items-start socials">
                                <li className="social">
                                    <a href="https://www.instagram.com/lucasdesign.sk/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                </li>
                                <li className="social">
                                    <a href="https://www.linkedin.com/in/lucas-rim%C3%B3ci-818479217/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                                </li>
                                <li className="social">
                                    <a href="https://pretlak.com/freelancers/detail/i0NLlOEnT" target="_blank" rel="noopener noreferrer">Pretlak</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <h4>{t('Informácie')}</h4>
                            <ul className="pages">
                                <li> <Link to={`${currentLang}/portfolio`}>{t('Portfólio')}</Link></li>
                                <li><Link to={`${currentLang}/#about-me`}>{t('O mne')}</Link></li>
                                <li><Link to={`${currentLang}/#services`}>{t('Služby')}</Link></li>
                                <li><Link to={`/ochrana-osobnych-udajov`}>{t('Ochrana osobných údajov')}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row gy-4">
                            <div className="col-sm-12 col-md-6">
                                <p>© <span id="year">{currentYear}</span> LucasDesign - All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </footer>
    )
}