import { Helmet } from 'react-helmet-async';

export default function PageNotFound() {
    return (
        <>
            <Helmet>
                <title>404 - LucasDesign</title>
                <meta name="title" content="404 - LucasDesign" />
                <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
                <meta name="keywords" content="web developer,developer,website,web,custom" />
                <meta name="robots" content="index, follow" />
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
            </Helmet>
            <section className="section-404">
                <h1>404</h1>
                <span>Je nám ľúto, ale požadovaná stránka nebola nájdená.</span>
                <a className="btn" href="/">
                    <div className="btn-inner">
                        <div className="btn-inner-text">
                            Prejsť na úvod</div>
                        <div className="btn-inner-text-hover">
                            Prejsť na úvod</div>
                    </div>
                </a>
            </section>
        </>
    )
}