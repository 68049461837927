import Icon from '../assets/icons/service-3.svg';
import { useTranslation } from 'react-i18next';

export default function ContactSection() {
    const { t } = useTranslation();

    return (
        <>
            <section id="git" className="section-git">
                <div className="section-git-inner">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-xl-5 left">
                            <img src={Icon} height="60" width="60" alt="Kontakt" />
                            <span>{t('Kontakt')}</span>
                            <h2>{t('Vytvorme spolu váš ďalší veľký projekt.')}</h2>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-7 right d-flex align-self-end justify-content-start justify-content-lg-end">
                            <a className="btn btn-secondary" href="mailto:info@lucasdesign.sk">
                                <div className="btn-inner">
                                    <div className="btn-inner-text">
                                        {t('Kontaktujte ma')}</div>
                                    <div className="btn-inner-text-hover">
                                        {t('Kontaktujte ma')}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}