// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

export default function LogoCarousel() {
    return (
        <>
            <Swiper
                spaceBetween={50}
                className="carousel"
                pagination={{
                    dynamicBullets: true,
                    el: '.swiper-custom-pagination',
                }}
                modules={[Pagination]}
                breakpoints={{
                    // when window width is >= 640px
                    1: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 640px
                    768: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 768px
                    1000: {
                        slidesPerView: 6,
                    },
                }}
            >
                <SwiperSlide>
                    <img src="/partner-logos/image10.png" height="120" width="190" alt="BestBody" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/partner-logos/loreta-logo-1-1.svg" height="120" width="190" alt="LorysBeauty" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/partner-logos/image11.png" height="120" width="190" alt="Zsolticars" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/partner-logos/Nivelen.svg" height="120" width="190" alt="Nivelen" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/partner-logos/image13.png" height="120" width="190" alt="Lichvar" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/partner-logos/nike-fond-sportu-favicon.jpg" height="120" width="190" alt="FastFx" />
                </SwiperSlide>
            </Swiper>
            <div className="swiper-custom-pagination-container" >
                <div className="swiper-custom-pagination"></div>
            </div>
        </>

    )
}