import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend'; // Only if you are loading translations from backend
import LanguageDetector from 'i18next-browser-languagedetector'; // To detect the user's language preference

// Configure i18n
i18n
  .use(HttpApi) // Only if using external translation files
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Passes i18n to React
  .init({
    supportedLngs: ['sk', 'en'], // List of supported languages
    fallbackLng: 'sk', // Fallback language if language is not found
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      // Configure detection to use URL path or hash
      order: ['path', 'htmlTag', 'cookie', 'localStorage', 'navigator', 'querystring'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Translation file path
    },
  });

export default i18n;
