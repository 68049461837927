import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { HashLink as Link } from 'react-router-hash-link';
import '../assets/css/fonts.css';
import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import ContactSection from '../components/ContactSection';
import LogoCarousel from '../components/LogoCarousel';
import PortfolioSection from '../components/PortfolioSection';
import { Fade } from "react-awesome-reveal";
const Blog = lazy(() => import('../blog/BlogList'));

export default function Home() {
  const { t } = useTranslation();

  const images = [
    'https://backend.lucasdesign.sk/wp-content/uploads/IMG_0225-1-jpg-jpg.webp',
    'https://backend.lucasdesign.sk/wp-content/uploads/rodmanbehanceArtboard-1-copy@2x-jpg.webp',
    'https://backend.lucasdesign.sk/wp-content/uploads/renders-all-3de-1-jpg.webp'
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1300); // Change image every 1 second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [images.length]);

  return (
    <>
      <Helmet>
        <title>{t('seo-title')} | LucasDesign</title>
        <meta name="title" content="LucasDesign" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
        <meta name="keywords" content="web developer,developer,website,web,custom" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="section-home-hero">
        <div className="container-inner">
          <div className="col left">
            <div>
              <span>Lucas Rimóci</span>
              <h1><Trans i18nKey="home-page-title" /></h1>
            </div>

            <ul className="vstack gap-2 align-items-start socials">
              <li className="social">
                <a href="https://www.instagram.com/lucasdesign.sk/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </li>
              <li className="social">
                <a href="https://www.linkedin.com/in/lucas-rim%C3%B3ci-818479217/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </li>
              <li className="social">
                <a href="https://pretlak.com/freelancers/detail/i0NLlOEnT" target="_blank" rel="noopener noreferrer">Pretlak</a>
              </li>
            </ul>
            <a href="mailto:info@lucasdesign.sk" className="btn btn-navbar">
              <div className="btn-inner">
                <div className="btn-inner-text">
                  info@lucasdesign.sk</div>
                <div className="btn-inner-text-hover">
                  info@lucasdesign.sk</div>
              </div>
            </a>
            <div className='scroll-down'>
              <svg className='mouse-circle' width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.6602 99.4502C49.7158 99.9228 49.9431 100.29 50.3413 100.551C50.7394 100.812 51.2278 100.943 51.8064 100.943C52.2296 100.943 52.5997 100.875 52.9172 100.738C53.2313 100.601 53.477 100.413 53.6544 100.173C53.8288 99.9337 53.9155 99.6617 53.9155 99.3569C53.9155 99.1015 53.855 98.8821 53.7337 98.6985C53.6093 98.5185 53.4507 98.3674 53.2576 98.2461C53.0649 98.1278 52.8625 98.0297 52.6509 97.9521C52.4394 97.8772 52.2453 97.8168 52.0679 97.7699L51.0973 97.5089C50.8485 97.4436 50.5717 97.3534 50.2668 97.2382C49.9584 97.123 49.6645 96.9657 49.3846 96.7669C49.1017 96.5706 48.8682 96.3188 48.6847 96.0109C48.5012 95.7029 48.4092 95.3249 48.4092 94.8768C48.4092 94.3604 48.5445 93.8939 48.8152 93.4769C49.0828 93.0604 49.4749 92.7288 49.9913 92.4831C50.5047 92.2373 51.1284 92.1143 51.8625 92.1143C52.5467 92.1143 53.1393 92.2251 53.6404 92.4458C54.138 92.6666 54.53 92.9745 54.8165 93.3696C55.0994 93.7647 55.2598 94.2237 55.297 94.7463H54.1025C54.0714 94.3854 53.9501 94.0866 53.7385 93.8506C53.5239 93.6171 53.2532 93.4428 52.9264 93.3276C52.5966 93.2159 52.2418 93.1598 51.8625 93.1598C51.4205 93.1598 51.0241 93.2312 50.6724 93.3744C50.3211 93.5207 50.0425 93.7227 49.8371 93.9811C49.6321 94.2421 49.5292 94.547 49.5292 94.8957C49.5292 95.2128 49.6181 95.4712 49.7955 95.6701C49.9725 95.8694 50.2059 96.031 50.4955 96.1554C50.7845 96.2798 51.0973 96.3889 51.4332 96.4822L52.6093 96.8181C53.3557 97.0327 53.9466 97.3394 54.3824 97.7375C54.8178 98.1357 55.0355 98.6569 55.0355 99.3008C55.0355 99.8356 54.8909 100.303 54.6019 100.701C54.3093 101.102 53.9172 101.413 53.4258 101.634C52.9313 101.858 52.3789 101.97 51.7692 101.97C51.1534 101.97 50.6058 101.859 50.1266 101.638C49.6475 101.421 49.2681 101.122 48.9878 100.743C48.7048 100.363 48.5555 99.932 48.5401 99.4502H49.6602Z" fill="black" />
                <path d="M42.4636 91.0222C43.1187 91.1716 43.6474 91.455 44.0499 91.8724C44.4524 92.2898 44.7192 92.7988 44.8501 93.3997C44.9811 94.0011 44.9667 94.6503 44.8077 95.3476C44.6456 96.0576 44.3723 96.6542 43.9877 97.1373C43.5992 97.6231 43.1349 97.9656 42.5948 98.1645C42.051 98.366 41.4637 98.3945 40.8329 98.2508C40.3415 98.1382 39.9195 97.9464 39.5667 97.6744C39.214 97.4023 38.9491 97.0738 38.7722 96.6888C38.5952 96.3038 38.5262 95.8859 38.5653 95.4357L39.6389 95.6805C39.6481 96.0178 39.7657 96.3415 39.9917 96.6516C40.214 96.9639 40.5617 97.1741 41.0348 97.2819C41.4533 97.3774 41.8452 97.352 42.2105 97.2057C42.5721 97.0616 42.8856 96.8123 43.1515 96.4584C43.4139 96.1067 43.6053 95.6669 43.7258 95.1391C43.8488 94.5995 43.8712 94.1098 43.7928 93.6709C43.7113 93.2311 43.5396 92.8645 43.2777 92.5719C43.0127 92.2784 42.6679 92.0835 42.2435 91.9863C41.9644 91.9228 41.7001 91.9136 41.4505 91.9587C41.2009 92.0038 40.9764 92.0993 40.7772 92.2451C40.578 92.3914 40.4159 92.5842 40.291 92.8238L39.2173 92.5785C39.3721 92.1799 39.6071 91.8426 39.9222 91.5667C40.2335 91.2933 40.6069 91.1041 41.0425 90.999C41.4743 90.8969 41.948 90.9044 42.4636 91.0222Z" fill="black" />
                <path d="M35.6644 88.9144L32.5546 95.3721L31.596 94.9104L32.0657 93.9349L31.9984 93.9025C31.7268 94.1653 31.389 94.3222 30.9848 94.3725C30.5807 94.4233 30.1852 94.3554 29.7984 94.1693C29.7255 94.1338 29.6351 94.0887 29.5272 94.0331C29.4192 93.9779 29.3386 93.9336 29.2852 93.9012L29.7711 92.892C29.8007 92.917 29.8717 92.9665 29.9841 93.0414C30.0924 93.1176 30.2124 93.1877 30.3441 93.2512C30.658 93.4023 30.97 93.4711 31.2801 93.4584C31.5861 93.447 31.8658 93.3607 32.1195 93.1995C32.3689 93.0401 32.5639 92.8145 32.7042 92.5232L34.6722 88.4365L35.6644 88.9144Z" fill="black" />
                <path d="M26.8223 83.3739C27.3282 83.7773 27.6761 84.2517 27.8659 84.797C28.0533 85.3402 28.0814 85.9139 27.9502 86.5175C27.8166 87.1189 27.5189 87.7094 27.0573 88.288C26.5918 88.8719 26.0796 89.2972 25.5208 89.5635C24.9596 89.8285 24.3933 89.9297 23.8218 89.8679C23.248 89.8044 22.7081 89.5705 22.2021 89.1671C21.6962 88.7637 21.3496 88.2906 21.1622 87.7471C20.9724 87.2017 20.9453 86.627 21.0808 86.023C21.214 85.4168 21.5133 84.822 21.9788 84.2381C22.4405 83.6595 22.9509 83.239 23.5102 82.9766C24.067 82.7125 24.6324 82.6122 25.2063 82.6757C25.7778 82.7379 26.3164 82.9705 26.8223 83.3739ZM26.2055 84.147C25.8213 83.8408 25.4265 83.6871 25.0213 83.6862C24.6161 83.6853 24.2259 83.7979 23.8507 84.0239C23.4755 84.2499 23.1385 84.5504 22.8398 84.9249C22.5411 85.2994 22.3224 85.6958 22.1836 86.1146C22.0448 86.5329 22.0199 86.9411 22.109 87.3384C22.198 87.7357 22.4346 88.0874 22.819 88.394C23.2032 88.7002 23.5989 88.8526 24.0061 88.8513C24.4132 88.8495 24.8054 88.7343 25.1825 88.5061C25.5597 88.2775 25.8976 87.9761 26.1963 87.6016C26.495 87.2271 26.7127 86.8316 26.8496 86.4155C26.9865 85.9994 27.0094 85.5942 26.9184 85.1991C26.8275 84.8045 26.5898 84.4536 26.2055 84.147Z" fill="black" />
                <path d="M12.5342 82.638L20.006 76.6797L20.6926 77.5408L13.2208 83.4992L12.5342 82.638Z" fill="black" />
                <path d="M6.69922 73.1199L15.3095 68.9736L15.7873 69.9657L7.17701 74.112L6.69922 73.1199Z" fill="black" />
                <path d="M11.5567 52.6009V55.5501H2V52.4704C2 51.5431 2.19132 50.7499 2.57396 50.0907C2.95349 49.431 3.49945 48.9255 4.21184 48.5738C4.92117 48.2225 5.77044 48.0469 6.7597 48.0469C7.75518 48.0469 8.61225 48.2238 9.33086 48.5786C10.0464 48.9334 10.597 49.4498 10.9827 50.1279C11.3654 50.8059 11.5567 51.6303 11.5567 52.6009ZM10.5301 54.3929L10.5301 52.6758C10.5301 51.8857 10.3777 51.2308 10.0728 50.7113C9.76795 50.1918 9.33396 49.8042 8.77089 49.5493C8.20782 49.2943 7.53744 49.1664 6.7597 49.1664C5.98818 49.1664 5.32401 49.2926 4.76716 49.5445C4.2072 49.7968 3.7779 50.173 3.47922 50.6737C3.17748 51.1747 3.0266 51.7985 3.0266 52.5449V54.3929H10.5301Z" fill="black" />
                <path d="M12.7126 43.0516C12.5686 43.6824 12.2921 44.2014 11.8832 44.6092C11.475 45.0139 10.9704 45.2877 10.3697 45.4314C9.76959 45.5724 9.10862 45.5601 8.38677 45.3954C7.65888 45.2294 7.05358 44.9522 6.57093 44.5645C6.08894 44.1738 5.75184 43.7073 5.55955 43.1659C5.36801 42.6211 5.34418 42.0333 5.4882 41.4025C5.63218 40.7717 5.9083 40.2539 6.31653 39.8493C6.72546 39.4417 7.2315 39.1679 7.83461 39.0279C8.43842 38.8849 9.10429 38.8965 9.83218 39.0626C10.554 39.2274 11.1544 39.5049 11.6334 39.895C12.113 40.282 12.4486 40.748 12.6402 41.2926C12.8324 41.8343 12.8566 42.4206 12.7126 43.0516ZM11.7481 42.8313C11.8575 42.3521 11.8246 41.9298 11.6496 41.5643C11.4745 41.1989 11.2038 40.8962 10.8374 40.6562C10.4709 40.4162 10.0542 40.2429 9.58711 40.1363C9.12006 40.0297 8.66789 40.0047 8.23058 40.0612C7.7933 40.1178 7.41499 40.2723 7.09568 40.5249C6.77636 40.7775 6.56204 41.1434 6.45267 41.6226C6.3433 42.1018 6.37764 42.5245 6.55573 42.8904C6.73383 43.2566 7.00759 43.5602 7.37706 43.8006C7.74648 44.0415 8.16479 44.215 8.63184 44.3219C9.0989 44.4283 9.54958 44.4528 9.98383 44.3959C10.4181 44.3385 10.7934 44.1835 11.1097 43.9298C11.4259 43.6767 11.6387 43.3105 11.7481 42.8313Z" fill="black" />
                <path d="M14.4678 36.7415L7.0625 35.5992L7.5646 34.5566L13.181 35.5417L13.2134 35.4745L8.93327 31.7145L9.44347 30.655L15.0269 31.6657L15.0593 31.5985L10.8041 27.8298L11.3062 26.7871L16.8164 31.8646L16.3467 32.84L10.7054 31.8635L10.6567 31.9644L14.9375 35.7661L14.4678 36.7415Z" fill="black" />
                <path d="M15.8959 25.5271L19.267 28.2154L18.5804 29.0764L12.9766 24.6075L13.6399 23.7757L14.5155 24.474L14.5737 24.401C14.3939 24.0427 14.3243 23.661 14.3649 23.2557C14.4032 22.8485 14.591 22.4333 14.9286 22.0101C15.2312 21.6307 15.5737 21.3608 15.9563 21.2003C16.3365 21.0379 16.7465 21.0028 17.1863 21.0949C17.6237 21.1851 18.0808 21.4203 18.5575 21.8004L22.1182 24.6401L21.4316 25.5011L17.9293 22.708C17.489 22.3569 17.0549 22.1978 16.6269 22.2305C16.1965 22.2613 15.8223 22.4761 15.5042 22.875C15.285 23.1498 15.1487 23.443 15.0952 23.7545C15.0437 24.0636 15.0828 24.3713 15.2125 24.6777C15.3422 24.984 15.5701 25.2672 15.8959 25.5271Z" fill="black" />
                <path d="M34.6013 15.6499C34.3939 15.7498 34.1801 15.7612 33.9601 15.6842C33.74 15.6072 33.58 15.465 33.4801 15.2576C33.3802 15.0502 33.3688 14.8364 33.4458 14.6163C33.5228 14.3963 33.665 14.2362 33.8724 14.1364C34.0798 14.0365 34.2936 14.025 34.5137 14.102C34.7337 14.1791 34.8937 14.3213 34.9936 14.5287C35.0598 14.666 35.0855 14.809 35.0707 14.9577C35.0588 15.105 35.0123 15.2414 34.9311 15.3668C34.8514 15.4881 34.7414 15.5824 34.6013 15.6499Z" fill="black" />
                <path d="M54.048 4.51986C53.9923 4.04697 53.765 3.67992 53.3669 3.4186C52.9687 3.15726 52.4803 3.0266 51.9017 3.0266C51.4786 3.0266 51.1084 3.09504 50.7909 3.23192C50.4768 3.36879 50.2311 3.55701 50.0537 3.79656C49.8794 4.03611 49.7926 4.30829 49.7926 4.61315C49.7926 4.86825 49.8531 5.08757 49.9744 5.27114C50.0988 5.45156 50.2574 5.60246 50.4505 5.72374C50.6433 5.84197 50.8456 5.93999 51.0572 6.01774C51.2688 6.09242 51.4628 6.15309 51.6402 6.19974L52.6108 6.46106C52.8596 6.52637 53.1365 6.6166 53.4413 6.73171C53.7497 6.84682 54.0436 7.00389 54.3235 7.20301C54.6064 7.39898 54.8399 7.65097 55.0234 7.95894C55.207 8.26696 55.2989 8.64492 55.2989 9.09288C55.2989 9.6093 55.1636 10.0759 54.8929 10.4928C54.6253 10.9096 54.2332 11.241 53.7168 11.4867C53.2035 11.7325 52.5797 11.8554 51.8456 11.8554C51.1614 11.8554 50.5688 11.7449 50.0677 11.524C49.5701 11.3032 49.1781 10.9952 48.8916 10.6001C48.6087 10.205 48.4484 9.74618 48.4111 9.22354H49.6056C49.6367 9.58442 49.758 9.88306 49.9696 10.1195C50.1842 10.3528 50.4549 10.527 50.7817 10.6421C51.1115 10.7541 51.4663 10.8101 51.8456 10.8101C52.2876 10.8101 52.684 10.7386 53.0357 10.5955C53.387 10.4492 53.6656 10.247 53.871 9.98884C54.076 9.72752 54.1789 9.42262 54.1789 9.07422C54.1789 8.75692 54.09 8.49871 53.9126 8.29959C53.7357 8.10051 53.5022 7.93875 53.2127 7.81431C52.9236 7.68987 52.6108 7.58098 52.2749 7.48764L51.0988 7.15168C50.3524 6.93701 49.7615 6.63061 49.3257 6.23241C48.8903 5.83421 48.6726 5.3131 48.6726 4.66918C48.6726 4.1341 48.8172 3.66743 49.1063 3.26925C49.3989 2.86795 49.7909 2.55685 50.2823 2.33598C50.7769 2.11199 51.3292 2 51.9389 2C52.5548 2 53.1023 2.11044 53.5815 2.33131C54.0607 2.54908 54.44 2.84772 54.7203 3.22726C55.0033 3.60677 55.1526 4.03764 55.168 4.51986H54.048Z" fill="black" />
                <path d="M61.2458 12.9475C60.5905 12.798 60.0618 12.5146 59.6593 12.0972C59.2568 11.6799 58.99 11.1707 58.859 10.5696C58.7281 9.96858 58.7425 9.31927 58.9015 8.62168C59.0636 7.91201 59.3369 7.31547 59.7215 6.83212C60.11 6.3464 60.5743 6.00405 61.1144 5.80502C61.6584 5.60366 62.2454 5.57497 62.8765 5.71895C63.3676 5.83108 63.7898 6.02315 64.1424 6.29511C64.495 6.56708 64.76 6.89555 64.937 7.28056C65.1139 7.66558 65.1831 8.0834 65.1441 8.53399L64.0701 8.28892C64.0609 7.95178 63.9435 7.62817 63.7175 7.31814C63.4955 7.00575 63.1477 6.79555 62.6746 6.68758C62.2559 6.59204 61.8638 6.61745 61.4985 6.76383C61.1372 6.90785 60.8236 7.15695 60.5577 7.51109C60.2953 7.86286 60.1039 8.30263 59.9834 8.83035C59.8604 9.37021 59.838 9.85969 59.9164 10.2988C59.9979 10.7386 60.1696 11.1049 60.4315 11.3976C60.6965 11.691 61.0412 11.8861 61.4657 11.983C61.7447 12.0467 62.0093 12.056 62.2589 12.0109C62.5082 11.9657 62.7329 11.8702 62.9322 11.7242C63.1315 11.5782 63.2935 11.3855 63.4184 11.1459L64.4919 11.391C64.3369 11.7896 64.1021 12.1269 63.7872 12.4028C63.4757 12.6764 63.1021 12.8655 62.6667 12.9703C62.2348 13.0728 61.7613 13.0652 61.2458 12.9475Z" fill="black" />
                <path d="M68.0439 15.0554L71.1539 8.59766L72.1122 9.05924L71.6427 10.0347L71.7097 10.067C71.9813 9.80422 72.3194 9.64754 72.7233 9.59699C73.1276 9.54649 73.5231 9.61434 73.9099 9.80062C73.9826 9.83571 74.0732 9.88096 74.181 9.93641C74.2892 9.99186 74.3698 10.0359 74.4232 10.0685L73.937 11.0775C73.9077 11.0529 73.8367 11.0032 73.7241 10.9283C73.6159 10.852 73.4959 10.7821 73.3641 10.7187C73.0505 10.5675 72.7382 10.4984 72.428 10.5114C72.1223 10.5229 71.8424 10.6091 71.5888 10.7701C71.3391 10.9297 71.1442 11.1551 71.0041 11.4466L69.0361 15.5332L68.0439 15.0554Z" fill="black" />
                <path d="M76.8857 20.5958C76.3798 20.1924 76.0321 19.718 75.842 19.1727C75.6549 18.6293 75.6264 18.0558 75.7579 17.4522C75.8914 16.8505 76.1893 16.2603 76.651 15.6814C77.1161 15.0976 77.6286 14.6724 78.1871 14.4059C78.7486 14.1412 79.315 14.0397 79.8862 14.1015C80.46 14.1653 81 14.3988 81.5059 14.8023C82.0119 15.2057 82.3583 15.6791 82.5458 16.2225C82.7359 16.7678 82.7626 17.3425 82.6273 17.9466C82.4941 18.5527 82.1949 19.1476 81.7293 19.7313C81.2677 20.3102 80.7569 20.7307 80.198 20.993C79.6409 21.2571 79.0758 21.3574 78.5016 21.2936C77.9304 21.2319 77.3917 20.9992 76.8857 20.5958ZM77.5025 19.8224C77.8866 20.1288 78.2817 20.2825 78.6869 20.2833C79.092 20.2841 79.4823 20.1715 79.8573 19.9455C80.2326 19.7195 80.5695 19.4192 80.8682 19.0447C81.1669 18.6701 81.3855 18.2736 81.5243 17.855C81.6632 17.4365 81.6882 17.0286 81.5992 16.6313C81.5099 16.2341 81.2734 15.8822 80.8892 15.5757C80.5046 15.2692 80.1091 15.1168 79.7018 15.1184C79.2948 15.12 78.9028 15.235 78.5257 15.4635C78.1485 15.6919 77.8104 15.9934 77.5117 16.368C77.2129 16.7426 76.9952 17.1379 76.8586 17.554C76.7215 17.9701 76.6987 18.3755 76.7898 18.7704C76.8805 19.1652 77.1183 19.5159 77.5025 19.8224Z" fill="black" />
                <path d="M91.1747 21.3317L83.703 27.2902L83.0166 26.4292L90.4883 20.4707L91.1747 21.3317Z" fill="black" />
                <path d="M97.0098 30.8496L88.3998 34.9961L87.9219 34.004L96.532 29.8574L97.0098 30.8496Z" fill="black" />
                <path d="M92.1514 51.3691V48.4199H101.708V51.4996C101.708 52.4269 101.517 53.2201 101.134 53.8794C100.754 54.539 100.209 55.0445 99.496 55.3962C98.7869 55.7475 97.9376 55.9231 96.9485 55.9231C95.9529 55.9231 95.0957 55.7462 94.3774 55.3914C93.6616 55.0366 93.1111 54.5202 92.7252 53.8421C92.3428 53.1641 92.1514 52.3397 92.1514 51.3691ZM93.1781 49.5772V51.2942C93.1781 52.0844 93.3305 52.7392 93.6354 53.2587C93.9402 53.7782 94.3743 54.1658 94.9372 54.4207C95.5004 54.6757 96.1706 54.8036 96.9485 54.8036C97.7199 54.8036 98.3839 54.6774 98.9411 54.4256C99.5008 54.1733 99.9301 53.797 100.229 53.2964C100.531 52.7953 100.681 52.1715 100.681 51.4252V49.5772H93.1781Z" fill="black" />
                <path d="M90.9959 60.9176C91.14 60.2868 91.4164 59.7678 91.8255 59.36C92.2338 58.9553 92.7379 58.6815 93.3389 58.5378C93.939 58.3968 94.5999 58.4091 95.3218 58.5738C96.0498 58.7398 96.6551 59.017 97.1378 59.4047C97.6196 59.7954 97.9569 60.2619 98.1492 60.8033C98.3406 61.3481 98.3642 61.936 98.2206 62.5667C98.0765 63.1975 97.8001 63.7152 97.3918 64.1199C96.9832 64.5277 96.4773 64.8015 95.8741 64.9412C95.2701 65.0844 94.6043 65.0726 93.8763 64.9066C93.1545 64.7419 92.5539 64.4642 92.0752 64.0744C91.5956 63.6872 91.26 63.2211 91.0682 62.6767C90.8759 62.1348 90.8518 61.5488 90.9959 60.9176ZM91.9604 61.1379C91.8509 61.6171 91.8838 62.0393 92.059 62.4051C92.2342 62.7704 92.5049 63.0731 92.8711 63.3131C93.2377 63.5531 93.6542 63.7261 94.1216 63.833C94.5885 63.9395 95.0406 63.9644 95.4781 63.9079C95.9153 63.8514 96.2937 63.6968 96.613 63.4441C96.9324 63.1918 97.1466 62.8256 97.2561 62.3464C97.3651 61.8672 97.331 61.4445 97.1527 61.0788C96.9749 60.7126 96.7011 60.409 96.3314 60.1686C95.9622 59.9277 95.5438 59.7542 95.0769 59.6473C94.6096 59.5409 94.1588 59.5164 93.7248 59.5733C93.2903 59.6307 92.9153 59.7857 92.5991 60.0394C92.2828 60.2925 92.0699 60.6587 91.9604 61.1379Z" fill="black" />
                <path d="M89.2402 67.2285L96.6454 68.3709L96.1434 69.4133L90.5271 68.4282L90.4947 68.4957L94.7746 72.2556L94.2643 73.3148L88.6813 72.3043L88.6489 72.3717L92.9038 76.1404L92.4019 77.1829L86.8916 72.1054L87.3612 71.1299L93.0028 72.1067L93.0514 72.0055L88.7707 68.204L89.2402 67.2285Z" fill="black" />
                <path d="M87.8123 78.4428L84.4413 75.7543L85.1277 74.8936L90.7317 79.3622L90.0685 80.194L89.1929 79.4958L89.1347 79.5689C89.3143 79.9272 89.3839 80.3088 89.3432 80.7139C89.3051 81.1213 89.1172 81.5365 88.7799 81.9596C88.4772 82.339 88.1347 82.6092 87.7519 82.7695C87.3717 82.9316 86.9617 82.9671 86.5219 82.8747C86.0843 82.7844 85.6275 82.5496 85.1509 82.1695L81.5898 79.3298L82.2767 78.4686L85.779 81.2619C86.2192 81.6127 86.6533 81.7722 87.0813 81.7393C87.5118 81.7087 87.8859 81.4936 88.2039 81.0946C88.4233 80.8199 88.5596 80.5269 88.613 80.2155C88.6647 79.9062 88.6253 79.5983 88.4956 79.2921C88.366 78.9855 88.1382 78.7026 87.8123 78.4428Z" fill="black" />
                <path d="M69.1073 88.3199C69.3149 88.22 69.5286 88.2086 69.7485 88.2857C69.9689 88.3628 70.1287 88.5047 70.2286 88.7124C70.3285 88.9195 70.3399 89.1333 70.2628 89.3536C70.1857 89.5735 70.0438 89.7338 69.8361 89.8337C69.6289 89.9335 69.4152 89.9449 69.1949 89.8678C68.975 89.7908 68.8151 89.6484 68.7148 89.4412C68.6487 89.3037 68.6233 89.1609 68.6377 89.0124C68.65 88.8648 68.6964 88.7286 68.7774 88.6033C68.8572 88.482 68.9671 88.3873 69.1073 88.3199Z" fill="black" />
              </svg>
              <svg className='mouse' width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M51.2149 44.6644C49.7647 44.6644 49.0268 44.8193 48.3475 45.1823C47.781 45.4804 47.3182 45.9432 47.0202 46.5096C46.6572 47.188 46.5023 47.9268 46.5023 49.3761V54.6176C46.5023 56.0677 46.6572 56.8056 47.0202 57.4849C47.3291 58.0624 47.77 58.5033 48.3475 58.8122C49.0259 59.1752 49.7647 59.3301 51.2149 59.3301H52.7897C54.2399 59.3301 54.9778 59.1752 55.657 58.8122C56.2235 58.5142 56.6863 58.0514 56.9844 57.4849C57.3474 56.8066 57.5023 56.0677 57.5023 54.6176V49.3761C57.5023 47.9259 57.3474 47.188 56.9844 46.5087C56.6863 45.9422 56.2235 45.4794 55.657 45.1814C54.9787 44.8193 54.2399 44.6644 52.7906 44.6644H51.2149ZM51.2149 42.8311H52.7897C54.6359 42.8311 55.6259 43.0859 56.5224 43.5653C57.4081 44.0342 58.1325 44.7585 58.6014 45.6443C59.0808 46.5408 59.3356 47.5308 59.3356 49.377V54.6185C59.3356 56.4646 59.0808 57.4546 58.6014 58.3511C58.1325 59.2369 57.4081 59.9613 56.5224 60.4301C55.6259 60.9096 54.6359 61.1644 52.7897 61.1644H51.2139C49.3678 61.1644 48.3778 60.9096 47.4813 60.4301C46.5955 59.9613 45.8711 59.2369 45.4023 58.3511C44.9238 57.4546 44.6689 56.4646 44.6689 54.6185V49.3761C44.6689 47.5299 44.9238 46.5399 45.4032 45.6434C45.8725 44.7578 46.5971 44.0338 47.4831 43.5653C48.3787 43.0859 49.3687 42.8311 51.2149 42.8311ZM51.0856 46.4977H52.9189V51.0811H51.0856V46.4977Z" fill="black" />
              </svg>
            </div>
          </div>
          <div className="col right">
            <div className='box green'>
              <h2>{t('Projekty')}</h2>

              <div className="box-images">
                <img src={images[currentImageIndex]} alt="Balmora" />
              </div>

              <Link to="/#work" className="btn btn-small-icon">
                <div className="btn-inner">
                  <div className="btn-inner-text">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.6711 12.16L9.55423 23.2769L7.72754 21.4502L18.8444 10.3333H9.0461V7.75H23.2544V21.9583H20.6711V12.16Z" fill="#222222" />
                    </svg>
                  </div>
                  <div className="btn-inner-text-hover">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.6711 12.16L9.55423 23.2769L7.72754 21.4502L18.8444 10.3333H9.0461V7.75H23.2544V21.9583H20.6711V12.16Z" fill="#222222" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            <div className='box dark'>
              <h2>{t('O mne')}</h2>

              <Link to="/#about-me" className="btn btn-small-icon">
                <div className="btn-inner">
                  <div className="btn-inner-text">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.6711 12.16L9.55423 23.2769L7.72754 21.4502L18.8444 10.3333H9.0461V7.75H23.2544V21.9583H20.6711V12.16Z" fill="#222222" />
                    </svg>
                  </div>
                  <div className="btn-inner-text-hover">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.6711 12.16L9.55423 23.2769L7.72754 21.4502L18.8444 10.3333H9.0461V7.75H23.2544V21.9583H20.6711V12.16Z" fill="#222222" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>


      </section>

      <Fade cascade triggerOnce direction="up">
        <section id="services" className="section-services">
          <div className="col-12">
            <div className="top">
              <h2>{t('V čom vám viem pomôcť?')}</h2>
              <span>{t('služby')}</span>
            </div>
          </div>

          <div className="services-loop">
            <div className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/ic_baseline-wordpress.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/logos_shopify.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/logos_react.png" alt="" />
                </div>
              </div>
              <h3>{t('Tvorba firemnej webstránky a e-shopu')}</h3>
              <p>{t('Moja špecializácia je vytváranie profesionálnych webových stránok, ktoré posunú vaše online podnikanie na nový level. Spoločne môžeme vytvoriť atraktívny firemný web alebo e-shop, ktorý presne vyhovuje vašim potrebám a cieľom.')}</p>
            </div>
            <Link to='/sprava-wp-stranok' className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/logos_google-drive.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/mdi_google-spreadsheet.png" alt="" />
                </div>
              </div>
              <h3>{t('Postarám sa o váš web a e-shop')}</h3>
              <p>{t('Okrem vývoja sa starám aj o údržbu webových stránok a e-shopov. To zahŕňa aktualizácie pluginov, zabezpečenie, optimalizáciu, import a export produktov a ďalšie.')}</p>
            </Link>
            <div className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/logos_google-search-console.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/yoast_icon_rgb 1.png" alt="" />
                </div>
              </div>
              <h3>SEO</h3>
              <p>{t('Optimalizujem váš web pre vyhľadávače (SEO) s cieľom zlepšiť pozície vo výsledkoch vyhľadávania, zvýšiť organickú návštevnosť a prilákať viac potenciálnych zákazníkov. Využite profesionálne SEO riešenia pre dlhodobý úspech a zviditeľnenie vašej značky či služieb na internete.')}</p>
            </div>
            <div className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/logos_google-analytics.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/logos_google-ads.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/logos_google-tag-manager.png" alt="" />
                </div>
              </div>
              <h3>{t('nastavenie analytics')}</h3>
              <p>{t('Ponúkam profesionálne nastavenie Google Analytics a ďalších nástrojov pre meranie návštevnosti a správania používateľov. Získajte prehľadné dáta pre lepšie rozhodovanie a optimalizáciu vášho webu či kampaní.')}</p>
            </div>
            <div className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/logos_python.png" alt="" />
                </div>
              </div>
              <h3>{t('Programovanie botov a Scrapovanie dát')}</h3>
              <p>{t('Vytváram inteligentných botov a ponúkam efektívne scrapovanie dát. Automatizujte procesy a získajte cenné informácie z webu rýchlo a spoľahlivo, šité na mieru vašim potrebám.')}</p>
            </div>
            <div className="services-card-wrapper">
              <div className='icons'>
                <div className='icon'>
                  <img src="./img/logos_react.png" alt="" />
                </div>
                <div className='icon'>
                  <img src="./img/logos_nodejs-icon.png" alt="" />
                </div>
              </div>
              <h3>{t('Nenašli ste, čo ste hľadali?')}</h3>
              <p>{t('Ak máte špecifické požiadavky alebo potrebujete riešenie na mieru, neváhajte ma kontaktovať. Rád vám pripravím ponuku podľa vašich potrieb a cieľov.')}</p>
            </div>
          </div>
        </section>
      </Fade>

      <div className='home-portfolio'>

        <div className="col-12">
          <div className="top">
            <h2>{t('Moje portfólio')}</h2>
            <Link to="/portfolio"><span>{t('portfólio')}</span></Link>
          </div>
        </div>
        <PortfolioSection postcount="4" />
        <div className='d-flex justify-content-center'>
        <Link to="/portfolio" className="btn btn-navbar mt-5">
          <div className="btn-inner">
            <div className="btn-inner-text">
              Všetky projekty</div>
            <div className="btn-inner-text-hover">
            Všetky projekty</div>
          </div>
        </Link>
        </div>
      </div>



      <Fade cascade triggerOnce direction="up">
        <LogoCarousel />
      </Fade>

      <section id="about-me" className="section-about-me">
        <div className="container-inner">
          <div className="row gx-7">
            <div className="col-12 col-lg-6 left">
              <div className="left-inner">
                <span>{t('kto som')}</span>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right">
              <h2>{t('Osoba s precíznou organizáciou a odhodlaním nájsť riešenie pre každý problém.')}</h2>
              <p>
                <Trans i18nKey="o-mne-popis-part1" />
                <br />
                {/*<Trans
                  i18nKey="o-mne-popis-part2"
                  components={{
                    1: <a href='#services'>web developer</a>,
                    2: <a href="https://ryvenia.sk/" target="_blank" rel="noopener noreferrer">Ryvenia</a>,
                    3: <a href="https://barneystudio.com/" target="_blank" rel="noopener noreferrer">Barney Studio</a>
                  }}
                />*/}
              </p>
            </div>
          </div>
        </div>
      </section>

      <ContactSection />

      <Suspense fallback={<div>Loading</div>}>
        <Blog />
      </Suspense>

    </>

  )
}