import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import '../assets/css/fonts.css';
import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import ContactSection from '../components/ContactSection';
import LogoCarousel from '../components/LogoCarousel';
import { Fade } from "react-awesome-reveal";
const Blog = lazy(() => import('../blog/BlogList'));

export default function SpravaWordPressstranok() {
  const { t } = useTranslation();


  useEffect(() => {
    // Inject Stripe script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Správa WordPress stránok | LucasDesign</title>
        <meta name="title" content="Správa WordPress stránok | LucasDesign" />
        <meta name="description" content="Postarám sa o správu vašej WordPress stránky. Pravidelné aktualizácie, optimalizácia výkonu, bezpečnostné opatrenia a podpora - všetko na jednom mieste. Kontaktujte ma!" />
        <meta name="keywords" content="správa WordPress stránok, WordPress aktualizácie, optimalizácia WordPress, bezpečnosť WordPress, WordPress podpora, LucasDesign" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className='container sprava-webstranok d-flex align-items-center '>
        <div className='container p-0'>
          <div className="row g-xl-5 d-flex align-items-start">
            <div className="col-12 col-xl-9">
              <h1 className='font-size-lg mb-4'>Správa WordPress stránok</h1>
              <p>Vaša WordPress stránka si zaslúži profesionálnu starostlivosť.
                Ponúkam kompletnú správu WordPress webov, ktorá zahŕňa aktualizácie, optimalizácie,
                bezpečnostné opatrenia a mnoho ďalšieho. Nechajte ma postarať sa o technické záležitosti,
                aby ste sa mohli sústrediť na svoje podnikanie.
                <br /><br />
                Služby, ktoré získate v rámci správy WordPress stránok:</p>
              <div className="checklist mt-5">
                <div className="checklist-item">
                  <span className="check-icon">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.604004" width="24" height="24" rx="12" fill="#222222" />
                      <path d="M10.4996 14.9831L17.3936 8.08838L18.4548 9.14888L10.4996 17.1041L5.72656 12.3311L6.78706 11.2706L10.4996 14.9831Z" fill="white" />
                    </svg>
                  </span>
                  <span>PRAVIDELNÉ AKTUALIZÁCIE</span>
                </div>
                <div className="checklist-item">
                  <span className="check-icon">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.604004" width="24" height="24" rx="12" fill="#222222" />
                      <path d="M10.4996 14.9831L17.3936 8.08838L18.4548 9.14888L10.4996 17.1041L5.72656 12.3311L6.78706 11.2706L10.4996 14.9831Z" fill="white" />
                    </svg>
                  </span>
                  <span>OPTIMALIZÁCIA VÝKONU</span>
                </div>
                <div className="checklist-item">
                  <span className="check-icon">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.604004" width="24" height="24" rx="12" fill="#222222" />
                      <path d="M10.4996 14.9831L17.3936 8.08838L18.4548 9.14888L10.4996 17.1041L5.72656 12.3311L6.78706 11.2706L10.4996 14.9831Z" fill="white" />
                    </svg>
                  </span>
                  <span>BEZPEČNOSTNÉ OPATRENIA</span>
                </div>
                <div className="checklist-item">
                  <span className="check-icon">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.604004" width="24" height="24" rx="12" fill="#222222" />
                      <path d="M10.4996 14.9831L17.3936 8.08838L18.4548 9.14888L10.4996 17.1041L5.72656 12.3311L6.78706 11.2706L10.4996 14.9831Z" fill="white" />
                    </svg>
                  </span>
                  <span>PODPORA A OPRAVY</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-3 mt-4 mt-xl-0 pricing">
              <h4 className='text-center mb-4'>Vyberte si program, ktorý vyhovuje vašim potrebám</h4>
              <stripe-pricing-table pricing-table-id="prctbl_1QW1QIG7NRIQxcNtl5QoibSf"
                publishable-key="pk_live_51QUVtoG7NRIQxcNtSTyVrEj8ojijuVTARiRXw1FA6NFSQGysXM9UzIbLJprhkKAYsVKA4JghDAUrUPupojsffJKs00BXswZLpV">
              </stripe-pricing-table>
            </div>
          </div>
        </div>
      </section>

      <Fade cascade triggerOnce direction="up">
        <section id="about-me" className="section-about-me">
          <div className="container-inner">
            <div className="row gx-7">
              <div className="col-12 col-lg-6 left">
                <div className="left-inner">
                  <span>FAQ</span>
                  <h2 className='mt-2 mb-4'>Časté otázky</h2>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 right">
                <Accordion transition transitionTimeout={250}>
                  <AccordionItem header="Prečo je správa WordPress stránok dôležitá?" initialEntered>
                    Pravidelná správa zvyšuje bezpečnosť a spoľahlivosť webu, znižuje riziko výpadkov a zlepšuje jeho výkonnosť.
                  </AccordionItem>

                  <AccordionItem header="Ako často sa vykonávajú aktualizácie?">
                    Aktualizácie sa vykonávajú podľa potreby, zvyčajne raz týždenne alebo hneď, ako je dostupná nová verzia.
                  </AccordionItem>

                  <AccordionItem header="Čo sa stane, ak sa na stránke vyskytne problém?">
                    V prípade problémov ponúkam rýchle riešenie a obnovenie zálohy, ak je to potrebné.
                  </AccordionItem>

                  <AccordionItem header="Môžem si správu WordPress stránky spravovať sám?">
                    Áno, ale profesionálna správa vám šetrí čas, znižuje riziká a poskytuje istotu, že váš web je vždy v správnych rukách.
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      </Fade>

      <ContactSection />

      <Suspense fallback={<div>Loading</div>}>
        <Blog />
      </Suspense>

    </>

  )
}