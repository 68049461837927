// BlogList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/blog/BlogList.scss';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Blog = () => {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backend.lucasdesign.sk/wp-json/wp/v2/posts');
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Blog - LucasDesign</title>
                <meta name="title" content="Blog - LucasDesign" />
                <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
                <meta name="keywords" content="web developer,developer,website,web,custom" />
                <meta name="robots" content="index, follow" />
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
            </Helmet>
            <section className="section-blog">
                <div className=" row gx-5 gy-5">
                    <div className="col-md-12 col-lg-4 left blog-list-header">
                        <h1>Blog</h1>
                        <p>{t('Blog ponúka prehľad technických tém o webových službách, vhodný pre digitálnych nadšencov a odborníkov. Získajte užitočné technické insighty online.')}</p>
                    </div>
                    <div className="col-md-12 col-lg-8 right">


                        {posts.slice(0, 1).map((post) => (
                            <div key={post.slug} className="post new-post">
                                <div className="banner-top">+++ Nové +++ Nové +++ Nové +++</div>
                                {post.yoast_head_json.og_image && (<div className="post-image">
                                    <Link onClick={() => window.scrollTo(0, 0)} to={`/blog/${post.slug}`}><img src={post.yoast_head_json.og_image[0].url} width={post.yoast_head_json.og_image[0].width} height={post.yoast_head_json.og_image[0].height} alt={post.title.rendered} /></Link>
                                </div>)}
                                <h2> <Link onClick={() => window.scrollTo(0, 0)} to={`/blog/${post.slug}`}>{post.title.rendered}</Link> </h2>
                                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                            </div>
                        ))}

                        <div className="row gx-5 gy-5">

                            {posts.slice(1).map((post) => (
                                <div key={post.slug} className="post col-md-12 col-lg-6 ">
                                    {post.yoast_head_json.og_image && (<div className="post-image">
                                        <Link onClick={() => window.scrollTo(0, 0)} to={`/blog/${post.slug}`}><img src={post.yoast_head_json.og_image[0].url} width={post.yoast_head_json.og_image[0].width} height={post.yoast_head_json.og_image[0].height} alt={post.title.rendered} /></Link>
                                    </div>)}
                                    <h2> <Link onClick={() => window.scrollTo(0, 0)} to={`/blog/${post.slug}`}>{post.title.rendered}</Link> </h2>
                                    <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog;
