import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PortfolioSection = ({ postcount, showAllButton }) => {
    const [portfolios, setPortfolios] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const url = `https://backend.lucasdesign.sk/wp-json/wp/v2/portfolio${postcount ? `?per_page=${postcount}` : ''}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response for portfolio was not ok');
                }

                const portfolioData = await response.json();
                setPortfolios(portfolioData);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await fetch('https://backend.lucasdesign.sk/wp-json/wp/v2/categories');
                if (!response.ok) {
                    throw new Error('Network response for categories was not ok');
                }

                const categoryData = await response.json();
                setCategories(categoryData);
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };

        // Use Promise.all to ensure both fetch operations are complete before rendering
        Promise.all([fetchPortfolios(), fetchCategories()]);
    }, [postcount]);

    // Skeleton loader for portfolio items
    const SkeletonLoader = () => (
        <div className="work-item skeleton">
            <div className="skeleton-img"></div>
            <div className="skeleton-title"></div>
            <div className="skeleton-buttons">
                <div className="skeleton-button"></div>
                <div className="skeleton-button"></div>
                <div className="skeleton-button"></div>
            </div>
        </div>
    );    

    return (
        <>
            <section id='work'>
                {loading ? (
                    // Render skeleton loaders while loading is true
                    <>
                        <SkeletonLoader />
                        <SkeletonLoader />
                    </>
                ) : Array.isArray(portfolios) && portfolios.length > 0 ? (
                    portfolios.slice(0, postcount).map(portfolio => (
                        <div key={portfolio.id} className="work-item">
                            {portfolio.acf.url && (
                                <a className="work-item-wrapper" href={portfolio.acf.url} target="_blank" rel="noopener noreferrer">
                                    <picture>
                                        <source srcSet={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}-jpg.webp`} type="image/webp" />
                                        <source srcSet={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}.jpg`} type="image/jpeg" />
                                        <img src={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}.jpg`} height="500" width="600" alt={portfolio.title.rendered} />
                                    </picture>
                                    <h4>{portfolio.title.rendered}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: portfolio.acf.description }} />
                                    <div className="work-buttons">
                                        {portfolio.categories.map(categoryId => (
                                            <CategoryButton key={categoryId} categoryId={categoryId} categories={categories} />
                                        ))}
                                    </div>
                                </a>
                            )}
                            {!portfolio.acf.url && (
                                <div className="work-item-wrapper">
                                    <picture>
                                        <source srcSet={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}-jpg.webp`} type="image/webp" />
                                        <source srcSet={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}.jpg`} type="image/jpeg" />
                                        <img src={`${portfolio.acf.featured_img.replace(/-jpg\.webp$|\.webp$|\.jpg$|\.png$/, '')}.jpg`} height="500" width="600" alt={portfolio.title.rendered} />
                                    </picture>
                                    <h4>{portfolio.title.rendered}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: portfolio.acf.description }} />
                                    <div className="work-buttons">
                                        {portfolio.categories.map(categoryId => (
                                            <CategoryButton key={categoryId} categoryId={categoryId} categories={categories} />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No portfolios available.</p>
                )}
            </section>
            {showAllButton === 'true' && (
                <div className="d-flex justify-content-center mt-5">
                    <Link onClick={() => window.scrollTo(0, 0)} to={`/portfolio`} className="btn">
                        <div className="btn-inner">
                            <div className="btn-inner-text">Zobraziť všetky</div>
                            <div className="btn-inner-text-hover">Zobraziť všetky</div>
                        </div>
                    </Link>
                </div>
            )}
        </>
    );
};

const CategoryButton = ({ categoryId, categories }) => {
    const category = categories.find(category => category.id === categoryId);

    const isExternalLink = link => /^https?:\/\//.test(link) || /^\/\//.test(link);

    const getButtonLink = () => {
        if (category && category.name === 'Ryvenia') {
            return 'https://ryvenia.sk';
        }
        if (category && category.name === 'Barney Studio') {
            return 'https://barneystudio.com/';
        }
        if (category && category.name === 'Web development') {
            return '#services';
        }
        return '';
    };

    const buttonLink = getButtonLink();
    const linkProps = {};
    const buttonProps = {};

    if (buttonLink) {
        linkProps.href = buttonLink;
        if (isExternalLink(buttonLink)) {
            linkProps.target = '_blank';
            linkProps.rel = 'noopener noreferrer';
        }
    } else {
        buttonProps.disabled = true;
    }

    return (
        <>
            {buttonLink && (
                <a {...linkProps}>
                    <button {...buttonProps} className={category && (category.name === 'Ryvenia' || category.name === 'Barney Studio') ? 'collab' : ''}>
                        {category ? category.name : ''}
                    </button>
                </a>
            )}
            {!buttonLink && (
                <span {...linkProps}>
                    <button {...buttonProps} className={category && (category.name === 'Ryvenia' || category.name === 'Barney Studio') ? 'collab' : ''}>
                        {category ? category.name : ''}
                    </button>
                </span>
            )}
        </>
    );
};

export default PortfolioSection;
