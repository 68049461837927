import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import { Helmet } from 'react-helmet-async';

export default function Contact() {


  return (
    <>
      <Helmet>
        <title>Kontakt - LucasDesign</title>
        <meta name="title" content="Kontakt - LucasDesign" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
        <meta name="keywords" content="web developer,developer,website,web,custom" />
        <meta name="robots" content="index, follow" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
      </Helmet>
      <section className="section-git">
        <div className="section-git-inner">
          <div className="row">
            <div className="col-md-12 col-lg-6 col-xl-5 left">
              <img src="./img/service-3.svg" alt="Kontakt" />
              <span>Kontakt</span>
              <h2>Vytvorme spolu váš ďalší veľký projekt.</h2>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-7 right d-flex align-self-end justify-content-start justify-content-lg-end">
              <a className="btn btn-secondary" href="mailto:info@lucasdesign.sk">
                <div className="btn-inner">
                  <div className="btn-inner-text">
                    Kontaktujte ma</div>
                  <div className="btn-inner-text-hover">
                    Kontaktujte ma</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}