// BlogPost.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/css/blog/BlogPost.scss';
import { Helmet } from 'react-helmet-async';
import ContactSection from '../components/ContactSection';
import Logo from '../logo.svg';

import { TwitterShareButton, FacebookShareButton } from 'react-share';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://backend.lucasdesign.sk/wp-json/wp/v2/posts?slug=${slug}`);
        const data = await response.json();
        setPost(data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPost();
  }, [slug]);

  const seoData = post.yoast_head_json;

  // Get current URL for share buttons
  const url = `https://lucasdesign.sk/blog/${slug}`;

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>{seoData?.title}</title>
        <meta name="description" content={seoData?.og_description} />
        <meta property="og:title" content={seoData?.og_title} />
        <meta property="og:description" content={seoData?.og_description} />
        <meta property="og:url" content={seoData?.og_url} />
        <meta property="og:type" content={seoData?.og_type} />
        <meta property="og:locale" content={seoData?.og_locale} />
        <meta property="og:site_name" content={seoData?.og_site_name} />
        {seoData?.og_image && seoData.og_image.map((image, index) => (
          <meta key={index} property="og:image" content={image.url} />
        ))}
        <meta name="twitter:card" content={seoData?.twitter_card} />
      </Helmet>
      <section className="section-post">
        <div className="container-inner">

          <div className=" row gx-5 gy-5 post-header">
            <div className="col-12">
              <h1>{post.title?.rendered}</h1>
            </div>
          </div>

        </div>

        <div className="single-post-image">
          {post.featured_media_url && (<div>
            <img src={post.featured_media_url} width={post.yoast_head_json.og_image[0].width} height={post.yoast_head_json.og_image[0].height} alt={post.title.rendered} />
          </div>)}
          <div className="banner-top">+++ {post.title?.rendered} +++</div>
        </div>

        <div className="container-inner single-post-content-container">

          <div className='single-post-share'>
            <span>Zdieľať</span>
            <ul className="vstack gap-2 share-buttons">
              <li className="share">
                <TwitterShareButton url={url}>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5839 6.39171C22.7886 6.74348 21.9453 6.9745 21.0818 7.07713C21.9919 6.53279 22.6731 5.6761 22.9984 4.66671C22.1443 5.17505 21.2078 5.5313 20.2318 5.724C19.5762 5.02252 18.7071 4.5573 17.7598 4.40065C16.8126 4.24401 15.84 4.40472 14.9935 4.85781C14.147 5.31089 13.4738 6.03097 13.0788 6.90608C12.6837 7.78119 12.5888 8.76232 12.8089 9.69692C11.0767 9.6101 9.38212 9.15997 7.83519 8.37574C6.28827 7.59151 4.92357 6.49072 3.82969 5.14484C3.44249 5.80989 3.23902 6.56591 3.2401 7.33546C3.2401 8.84588 4.00885 10.1803 5.1776 10.9615C4.48595 10.9397 3.80951 10.7529 3.20469 10.4167V10.4709C3.2049 11.4768 3.55299 12.4517 4.18995 13.2303C4.8269 14.0089 5.71352 14.5432 6.69948 14.7428C6.05741 14.9168 5.38417 14.9424 4.73073 14.8178C5.00872 15.6836 5.55053 16.4409 6.2803 16.9835C7.01008 17.5261 7.89128 17.8269 8.80052 17.8438C7.89685 18.5535 6.86216 19.0782 5.7556 19.3877C4.64905 19.6973 3.49232 19.7857 2.35156 19.648C4.34291 20.9286 6.66104 21.6085 9.02865 21.6063C17.0422 21.6063 21.4245 14.9678 21.4245 9.21046C21.4245 9.02296 21.4193 8.83338 21.4109 8.64796C22.2639 8.03147 23.0001 7.26777 23.5849 6.39275L23.5839 6.39171Z" fill="#222222" />
                  </svg>
                </TwitterShareButton>
              </li>
              <li className="share">
                <FacebookShareButton url={url}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9987 2.58337C7.24557 2.58337 2.58203 7.24692 2.58203 13C2.58203 18.199 6.39141 22.5084 11.3716 23.2907V16.0105H8.72578V13H11.3716V10.7052C11.3716 8.09483 12.9258 6.65317 15.306 6.65317C16.4456 6.65317 17.6372 6.85629 17.6372 6.85629V9.41879H16.3247C15.0299 9.41879 14.6268 10.2219 14.6268 11.0459V13H17.5154L17.0539 16.0105H14.6268V23.2907C19.606 22.5094 23.4154 18.198 23.4154 13C23.4154 7.24692 18.7518 2.58337 12.9987 2.58337Z" fill="#222222" />
                </svg>
                </FacebookShareButton>
              </li>
            </ul>
          </div>

          <div className='single-post-content'>
            <div dangerouslySetInnerHTML={{ __html: post.content?.rendered }} />

            {post.acf && post.acf.zaver && (
              <div className='single-post-conslusion'>
                <h3>Záver</h3>
                <p dangerouslySetInnerHTML={{ __html: post.acf.zaver }} />
                <div className='author'>
                  <img src={Logo} alt='LucasDesign' />
                  <div className='author-info'>
                    <span>LucasDesign</span>
                    <a href="mailto:info@lucasdesign.sk">info@lucasdesign.sk</a>
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>

        <ContactSection />

      </section >
    </>

  );
};

export default BlogPost;
